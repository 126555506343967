import React, {memo} from "react";
import { Paper, Grid, Typography } from '@material-ui/core';
import theme from './style';
import {useConfig} from "../../context/configContext";
import translate from '../../misc/applyTranslation';

const SmartUsage = props => {
    const config = useConfig();
    const classes = theme(config);

    function decodeHTML(html) {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
      }
      const decodedHTML = decodeHTML( translate('use-electricity-smarter', 'use_electricity_smarter') );
    return (
        <>
            <Paper className={classes.container}>
            <div className='title'>{translate('use-electricity-smarter', 'use_electricity_smarter_panel_title')}</div>
            <div>
                <div dangerouslySetInnerHTML={{ __html: decodedHTML }} />
            </div>
            </Paper>
        </>
    )
}

export default SmartUsage;