import React, {memo, useEffect, useState} from 'react';
import theme from './style';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {AppBar, Toolbar, Drawer, Tooltip, withWidth, Paper} from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import DrawerMenu from '../../components/drawerMenu';
import Breadcrumbs from '../../components/breadcrumbs';
import {BorderSelect} from '../../components/inputs';
import {setLogOut, setUserType, fetchUserData, setSelectedCustomer, setUserData} from '../../redux/actions/login';
import MenuIcon from '@material-ui/icons/Menu';
import {useHistory, useLocation} from 'react-router-dom';
import translate from '../../misc/applyTranslation';
import {fetchTranslations} from "../../redux/actions/settings";
import {useConfig} from "../../context/configContext";

const TemplateDashboard = (props) => {
    const config = useConfig();
    const classes = theme(config);
    const location = useLocation();
    const history = useHistory();
    const {
        setLogOut,
        fetchUserData,
        children,
        width,
        setUserType,
        userData,
        access_token,
        refresh_token,
        user_admin,
        connectedCustomers,
        selectedCustomer,
        setSelectedCustomer,
        fetchTranslations,
        setUserData
    } = props;
    let responsiveTest = (width === 'xs' || width === 'sm');
    const [open, setOpen] = useState(false)
    const [showLanguages, setShowLanguages] = useState(false);
    let urlArray = location.pathname.split("/");
    const handleMenuToggle = () => {
        setOpen(open ? false : true);
    }
    let drawerOpen = (!responsiveTest) || (responsiveTest && open)
    // const toggleUsertype = () => {
    //   setUserType();
    //   history.push('/en')
    // }
    const displayLanguages = () => {
        setShowLanguages(!showLanguages);
    }
    const handleLanguageChange = params => {
        urlArray[1] = params.lang;
        history.replace(urlArray.join("/"));
        fetchTranslations({lang: params.code})
    }
    const handleChangeCustomer = customerId => {
        const customer = connectedCustomers.find(c => c.id === customerId);
        setUserData(customer);
        setSelectedCustomer(customerId)
    }
    useEffect(() => {
        fetchUserData({
            access_token,
            refresh_token,
            admin: user_admin,
        })
        // eslint-disable-next-line
    }, [])
    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar} elevation={0}>
                <Toolbar className={classes.appBarToolbar}>
                    {!responsiveTest &&
                        <div className={classes.appBarToolbarLogo}/>
                    }
                    {responsiveTest &&
                        <div className={`${classes.responsiveMenuToolbar} ${open ? classes.menuOpen : ''}`}>
                            <MenuIcon style={{cursor: 'pointer', marginRight: 20, marginLeft: 10, fontSize: 30}}
                                      onClick={handleMenuToggle}/>
                            <div className={classes.appBarToolbarSmallLogo}/>
                        </div>
                    }
                    <div className={classes.appBarToolbarUser}>
                        {!user_admin && connectedCustomers.length > 0 &&
                            <BorderSelect
                                onChange={(e) => handleChangeCustomer(e)}
                                selected={selectedCustomer}
                                items={connectedCustomers}
                            />
                        }
                        <div style={{position: "relative"}} onClick={displayLanguages}>
                            <img className={classes.appBarToolbarLanguage} src={urlArray[1] === "se" ? "/se.png" : "/gb.png"} alt="language"/>
                            {showLanguages &&
                                <Paper className={classes.appToolbarLanguagePaper}>
                                    {urlArray[1] === "se" && <img className={classes.appBarToolbarLanguage} src="/gb.png" alt="en" onClick={() => handleLanguageChange({lang: "en", code: "en-US"})}/>}
                                    {urlArray[1] === "en" && <img className={classes.appBarToolbarLanguage} src="/se.png" alt="se" onClick={() => handleLanguageChange({lang: "se", code: "sv-SE"})}/>}
                                </Paper>
                            }
                        </div>
                        <img className={classes.appBarToolbarUserIcon} src="/user.png" alt='user'/>
                        <span className={classes.appBarToolbarUserName}>{userData.name || userData.email}</span>
                        <Tooltip title={translate('', 'tooltip_logout')} onClick={() => setLogOut({user_admin})}><PowerSettingsNewIcon
                            className={classes.appBarToolbarLogout}/></Tooltip>
                    </div>
                </Toolbar>
            </AppBar>
            {drawerOpen &&
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <DrawerMenu
                        closeMenu={() => setOpen(false)}
                        isResponsive={responsiveTest}
                    />
                </Drawer>
            }
            <main className={classes.content}>
                <Breadcrumbs/>
                {children}
            </main>
        </div>
    );
}
TemplateDashboard.propTypes = {
    setLogOut: PropTypes.func.isRequired,
    setUserType: PropTypes.func.isRequired,
    fetchUserData: PropTypes.func.isRequired,
}
const mapStateToProps = store => ({
    userData: store.login.userData,
    access_token: store.login.access_token,
    refresh_token: store.login.refresh_token,
    user_admin: store.login.user_admin,
    connectedCustomers: store.login.connectedCustomers,
    selectedCustomer: store.login.selectedCustomer,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({
        setLogOut,
        setUserType,
        fetchUserData,
        setSelectedCustomer,
        fetchTranslations,
        setUserData
    }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(withWidth()(TemplateDashboard)));
