const months = [
    {
        month: "moth_january",
        id: 1
    },
    {
        month: "moth_february",
        id: 2
    },
    {
        month: "moth_march",
        id: 3
    },
    {
        month: "moth_april",
        id: 4
    },
    {
        month: "moth_may",
        id: 5
    },
    {
        month: "moth_june",
        id: 6
    },
    {
        month: "moth_july",
        id: 7
    },
    {
        month: "moth_august",
        id: 8
    },
    {
        month: "moth_september",
        id: 9
    },
    {
        month: "moth_october",
        id: 10
    },
    {
        month: "moth_november",
        id: 11
    },
    {
        month: "moth_december",
        id: 12
    },
];

export default months;