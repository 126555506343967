import moment from "moment";
import APICall from "../../../misc/APICall";
import translate from "../../../misc/applyTranslation";

export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SET_INVOICE_VIEW_ID = 'SET_INVOICE_VIEW_ID';
export const SET_LOADING_CUSTOMER = 'SET_LOADING_CUSTOMER';
export const SET_ERROR_CUSTOMER = 'SET_ERROR_CUSTOMER';
export const FETCH_USAGE_FILE = "FETCH_USAGE_FILE";
export const SET_DOWNLOAD_USAGE_FILE_LOADING = "SET_DOWNLOAD_USAGE_FILE_LOADING";

const server = true ? window.location.origin : 'https://staging.cheap.cust.commerz.se';

export const setSelectedTab = (payload) => {
  return {
    type: SET_SELECTED_TAB,
    payload: payload
  }
}
export const setInvoiceViewId = (payload) => {
  return {
    type: SET_INVOICE_VIEW_ID,
    payload: payload
  }
}
export const setLoadingCustomer = (payload) => {
  return {
    type: SET_LOADING_CUSTOMER,
    payload: payload
  }
}
export const setErrorCustomer = (payload) => {
  return {
    type: SET_ERROR_CUSTOMER,
    payload: payload
  }
}

export const setDownloadusageFileLoading = (payload) => {
  return {
    type: SET_DOWNLOAD_USAGE_FILE_LOADING,
    payload: payload
  }
}

export const fetchUsageFile = (payload) => {
  return async dispatch => {
    try {
      let {access_token, date, customer_id} = payload; 

      dispatch(setDownloadusageFileLoading(true));

      const response = await fetch(`${server}/api/customer/props?date=${moment(date).format("YYYY-MM-DD")}&customer_id=${customer_id}`, {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      });
  
      if (response.status >= 400) {
          if(response.status === 404) {
            dispatch(setErrorCustomer({error: translate('usage', 'no_readings_found')}));
          } else {
            dispatch(setErrorCustomer({error: translate('', 'generic_api_call_error')}));
          }
          dispatch(setDownloadusageFileLoading(false));
          return;
      }

      const blob = await response.blob();

      let filename = "download.xlsx"; // Default filename
      const contentDisposition = response.headers.get('content-disposition');
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="?([^"]+)"?/);
        if (match && match[1]) filename = match[1];
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      dispatch(setDownloadusageFileLoading(false));
      a.click();
      document.body.removeChild(a);

      // Release URL object
      window.URL.revokeObjectURL(url);

    } catch (error) {
      dispatch(setErrorCustomer(error));
      dispatch(setDownloadusageFileLoading(false));
    }
  }


  // let {access_token, date, customer_id} = payload;

  // return async dispatch => {
  //   console.log("here")
  //   return await fetch(`${server}/api/customer/props?date=${moment(date).format("YYYY-MM-DD")}&customer_id=${customer_id}`, {
  //     headers: {
  //       'Authorization': `Bearer ${access_token}`
  //     }
  //   })
  //   .then(response => {
  //     response.blob().then(blob => {
  //       // Create a URL object from the Blob
  //       const url = window.URL.createObjectURL(blob);
  //       // Open the PDF file in a new window
  //       window.open(url);
  //     });
  //   })
  //   .catch(error => {
  //     dispatch(setErrorCustomer(error))
  //   });
  // }
}