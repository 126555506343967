import { makeStyles } from "@material-ui/core/styles";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      display: 'inline-flex',
      width: '100%',
    },
    buttons: {
      border: `2px solid ${props.color3}`,
      display: 'flex',
      justifyContent: 'center',
      alignitems: 'center',
      marginRight: 10,
      padding: '8px 15px',
      borderRadius: '0px 4px 4px 0px',
      fontSize: 14,
      fill: props.color2,
    },
    textinput: {
      width: '100%',
      // width: 'calc(100% - 20px)',
      fontWeight: 400,
      fontSize: 14,
      marginLeft: 10,// '0px 10px',
      border: 'none',
      '& .Mui-disabled': {
        background: '#eee !important',
        cursor: 'not-allowed'
      },
      '& input': {
        height: 20,
        background: 'white',
        // borderRadius: 4,
        borderRadius: '4px 0px 0px 4px',
        padding: '6px 12px',
        '-webkit-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        '-moz-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        border: '1px solid #ddd',
      },
      '& input:focus': {
        border: `1px solid ${props.color3}`,
        '-webkit-box-shadow': `inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px ${props.color3}`,
        boxShadow: `inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px ${props.color3}`,
      },
      '& ::before': {
        borderBottom: 'none !important',
      },
      '& ::after': {
        borderBottom: 'none !important',
      },
    }
  }));
  return styles();
}
export default theme;

