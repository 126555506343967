import { makeStyles } from "@material-ui/core/styles";

const theme = props => {
    const styles = makeStyles(() => ({
        container: {    
            marginBottom: 15,
            padding: 10,
            
            '& .title': {
                color: '#989898',
                fontWeight: "bold",
                fontSize: 20,
                marginBottom: 20,
              },
              '& .text': {
                fontSize: 13,
                fontWeight: 400,
                color: '#989898',
                marginBottom: 30,
              },
        },
    }));
    return styles();
};

export default theme;