import React from 'react';
import theme from './style'
import {TextField} from '@material-ui/core';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import {useConfig} from "../../../context/configContext";


const SearchInput = (props) => {
    const config = useConfig();
    const classes = theme(config);
    const {placeholder, value, onChange, disabled, onClick} = props;
    return (
        <div className={classes.container}>
            <TextField
                disabled={disabled}
                onChange={(e) => {
                    if (onChange) onChange(e)
                }}
                className={classes.textinput}
                placeholder={placeholder}
                value={value}
            />
            <SearchIcon
                onClick={(e) => {
                    if (onClick) onClick(e)
                }}
                className={classes.buttons}
            />
        </div>
    );
}
SearchInput.propTypes = {
    onClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
}
export default SearchInput;