
import React, { memo, } from 'react';
import theme from './style';
import { Paper, Grid, Typography } from '@material-ui/core';
import translate from '../../misc/applyTranslation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import he from 'he'
import {useConfig} from "../../context/configContext";
const ContactsView = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const { userData } = props;
  const {
    address1,
    address2,
    name,
    name2,
    post_code,
    city,
    country,
    email1,
    email2,
    tel1,
    tel2,
  } = userData;

  function decodeHTML(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  const decodedHTML = decodeHTML( translate('general-info', 'general_info') );

  return (
    <React.Fragment>
      <Paper className={classes.container}>
        <div className='title'>{translate('general-info', 'general_info_panel_title')}</div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: decodedHTML }} />
        </div>
      </Paper>
    </React.Fragment>
  )
}

ContactsView.propTypes = {
}
const mapStateToProps = store => ({
  userData: store.login.userData,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(ContactsView));