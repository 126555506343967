import React from 'react';
import theme from './style'
import Button from '@material-ui/core/Button';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import PropTypes from 'prop-types';
import {useConfig} from "../../../context/configContext";
import { CircularProgress } from '@material-ui/core';


const NewButton = (props) => {
  const config = useConfig();
  const classes = theme(config);
  const { text, onClick, loading } = props;
  return (
    <Button
      onClick={(e) => { if (onClick) onClick(e) }}
      className={classes.button}
      variant="outlined"
      startIcon={loading ? <CircularProgress className={classes.circle}/> : <InsertDriveFileOutlinedIcon />}
      disabled={loading}
    >
      {text}
    </Button>
  );
}
NewButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}
export default NewButton;