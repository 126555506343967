import {store} from '../redux/store';
import {getConfig} from "../context/configContext";

const parseTranslation = translation => {
    const config = getConfig();
    if (!config) {
        console.log("Config is not loaded yet");
        return translation;
    }

    let r = /##(.+?)##/g;
    let m;
    let out = translation;
    while (m = r.exec(translation)) {
        let k = m[1];
        if (typeof config[k] != "undefined") {
            out = out.replace(m[0], config[k]);
        }
    }
    return out;
}

const translate = (area, key) => {
    const state = store.getState();
    const config = getConfig();
    if (!config) {
        return area + ' - ' + key; // Fallback in case config is unavailable
    }

    let translations = state.settings.translations;
    try {
        let translation = translations[`${config.namespace}${area.length > 0 ? '/' + area : ''}`].find(item => item.hash === key).text;
        return parseTranslation(translation);
    } catch (error) {
        return area + ' - ' + key;
    }
}
export default translate;
