import { setSelectedMenu } from '../states';
import { setLoadingInvoices } from '../invoices';
import { setLoadingCustomer } from '../customer';
import { setLoadingSuppliers } from '../suppliers';
import { setLoadingSupplierInvoices } from '../supplierInvoices';
import { setLoadingFiles } from '../files';

import APICall from '../../../misc/APICall';
import { store } from '../../store';
import {
  SET_FORM_FIELDS,
  SET_LOADING_LOGIN,
  SET_ERROR_LOGIN,
  SET_LOGIN_DATA,
  SET_LOG_OUT,
  SET_USER_TYPE,
  INITATE_BANK_AUTH_LOGIN,
  SET_USER_DATA,
  SET_LIST_CONNECTED_USERS,
  SET_SELECTED_CUSTOMER,
  SET_BANKID_PROCCESS_STOP_FLAG, INITIATE_HOSTED_BANK_AUTH_LOGIN,
} from './types';

export const setLoadingLogin = (payload) => {
  return {
    type: SET_LOADING_LOGIN,
    payload: payload
  }
}
export const setErrorLogin = (payload) => {
  return {
    type: SET_ERROR_LOGIN,
    payload: payload
  }
}
export const setFormData = (payload) => {
  return {
    type: SET_FORM_FIELDS,
    payload: payload
  }
}
export const submitLoginPassword = (payload) => {
  return async dispatch => {
    dispatch(setLoadingLogin(true))
    var formdata = new FormData();
    formdata.append("username", payload.email);
    formdata.append("password", payload.password);
    return await APICall({
      url: 'login_password',
      verb: 'post',
      body: formdata,
    }).then(res => {
      dispatch({
        type: SET_LOGIN_DATA,
        payload: { ...res, user_admin: true }
      })
      dispatch(setLoadingLogin(false))
      return true;
    }).catch(error => {
      dispatch(setErrorLogin(error))
    })
  }
}
export const setLogOut = (payload) => {
    const {user_admin} = payload;
  return async dispatch => {
    dispatch(setLoadingInvoices(false))
    dispatch(setLoadingCustomer(false))
    dispatch(setLoadingSuppliers(false))
    dispatch(setLoadingSupplierInvoices(false))
    dispatch(setLoadingFiles(false))
    dispatch({ type: SET_LOG_OUT })

    let lang = "/se";
    if (window.location.pathname) {
        if (window.location.pathname.match(/.{3}/g)) {
            lang = window.location.pathname.match(/.{3}/g)[0];
        }
    }

      if (user_admin) {
          window.location = `${window.location.origin}${lang}/login`
      } else {
         window.location = `${window.location.origin}${lang}`
      }
  }
}
export const setUserType = () => {
  return async dispatch => {
    await dispatch({
      type: SET_USER_TYPE,
    })
    await dispatch(setSelectedMenu(0))
  }
}
export const initiateBankAuth = (payload) => {
  const { ssn } = payload;
  return async dispatch => {
    dispatch(setLoadingLogin(true))
    var formdata = new FormData();
    formdata.append('grant_type', 'bankid');
    formdata.append('ssn', ssn);
    return await APICall({
      url: 'begin_login_procedure',
      verb: 'post',
      body: formdata,
    }).then(res => {
      dispatch({
        type: INITATE_BANK_AUTH_LOGIN,
        payload: res
      })
      dispatch(checkBankAuth({ sessionId: res.sessionId }))
      return true;
    }).catch(error => {
      dispatch(setErrorLogin(error))
    })
  }
}
export const checkBankAuth = (payload) => {
  const state = store.getState();
  const { sessionId } = payload;
  if (!state.login.abortedBankIdProccess) {
    return async dispatch => {
      var formdata = new FormData();
      formdata.append('grant_type', 'bankid');
      formdata.append('session_id', sessionId);
      return await APICall({
        url: 'check_login_procedure_status',
        verb: 'post',
        body: formdata,
      }).then(res => {
        if (res.token_type) {
          dispatch({
            type: SET_LOGIN_DATA,
            payload: { ...res, user_admin: false }
          })
        } else {
          setTimeout(() => {
            dispatch(checkBankAuth(payload))
          }, 1000);
        }
      }).catch(error => {
        dispatch(setErrorLogin(error))
      })
    }
  } else {
    return {
      type: INITATE_BANK_AUTH_LOGIN,
      payload: { sessionId: false }
    }
  }
}
const ajaxRenewToken = async (payload) => {
  var formdata = new FormData();
  formdata.append("token", payload);
  return await APICall({
    body: formdata,
    url: `refresh_password`,
    verb: 'post',
  }).then(async res => {
    return res;
  }).catch(error => {
    return false;
  })
}
export const renewToken = async (redoFunction, payload, dispatch) => {
  await ajaxRenewToken(payload.refresh_token).then(async res => {
    if (res) {
      await dispatch({ type: SET_LOGIN_DATA, payload: res })
      let pload = JSON.parse(JSON.stringify(payload));
      pload.access_token = res.access_token;
      await dispatch(redoFunction(pload, false))
    } else { dispatch(setLogOut(payload)) }
  }).catch(err => {
    dispatch(setLogOut(payload))
  })

}
export const fetchUserData = (payload, firstTime = true) => {
  let { access_token, admin } = payload;
  return async dispatch => {
    dispatch(setLoadingLogin(true))
    return await APICall({
      access_token: access_token,
      url: admin ? `current_user` : `current_customer`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_USER_DATA,
        payload: res
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchUserData, payload, dispatch)
      } else {
        dispatch(setErrorLogin(error))
      }
    })
  }
}
export const fetchConnectedUsers = (payload, firstTime = true) => {
  let { access_token } = payload;
  return async dispatch => {
    dispatch(setLoadingLogin(true))
    return await APICall({
      access_token: access_token,
      url: `user/connected-customers`,
      verb: 'get',
    }).then(res => {
      if (res.length === 0) {
        dispatch(setLogOut(payload))
      } else {
        dispatch({
          type: SET_LIST_CONNECTED_USERS,
          payload: {
            data: res.map(item => { return { ...item, text: item.name } }),
            selected: res[0].id
          }
        })
      }
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchConnectedUsers, payload, dispatch)
      } else {
        dispatch(setErrorLogin(error))
      }
    })
  }
}
export const setSelectedCustomer = (payload) => {
  return {
    type: SET_SELECTED_CUSTOMER,
    payload: payload
  }
}
export const stopBankIdAuthProccess = (payload) => {
  return {
    type: SET_BANKID_PROCCESS_STOP_FLAG
  }
}

export const setUserData = payload => {
  return {
    type: SET_USER_DATA,
    payload: payload
  }
}
