import React, { memo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { Paper, Grid } from "@material-ui/core";
import moment from "moment";
import theme from './style';
import translate from "../../misc/applyTranslation";
import { ExcelButton } from "../../components/buttons";
import { fetchUsageFile } from "../../redux/actions/customer";
import MockDataYears from "../../misc/mockDataYears";
import MockDataMonths from "../../misc/mockDataMonths";
import { BorderSelect } from "../../components/inputs";

const UserUsage = props => {
    const classes = theme();

    const {access_token, refresh_token, selectedCustomer, fetchUsageFile, usageFileLoading} = props;

    const [year, setYear] = useState(MockDataYears[0].id);
    const [month, setMonth] = useState(MockDataMonths[0].id);


    const currentMonth = moment().month();
    let months = [];
    if(moment().year() > year) {
        months = MockDataMonths;
    } else {
        months = MockDataMonths.slice(0, currentMonth);
    }
    const handleDownloadClick = () => {
        const date = moment({year: year, month: month-1}).startOf("month");
        fetchUsageFile({
            access_token,
            refresh_token,
            date,
            customer_id: selectedCustomer
        })
    }

    return (
        <Paper className={classes.container}>
             <div className="title">{translate("usage", "user_usage_page_title")}</div>
             <div className="text">{translate("usage", "user_usage_page_sub_title")}</div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} style={{marginLeft: "-10px"}}>
                    <BorderSelect
                        selected={year}
                        onChange={(e) => setYear(e)}
                        items={MockDataYears}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} style={{marginLeft: "-10px"}}>
                    <BorderSelect
                        selected={month}
                        onChange={(e) => setMonth(e)}
                        items={months}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} style={{marginLeft: "-10px"}}>
                    <ExcelButton text={translate("usage", "user_usage_download_button")} onClick={handleDownloadClick} loading={usageFileLoading}/>
                </Grid>
            </Grid>
        </Paper>
    )
};

const mapStateToProps = store => ({
    access_token: store.login.access_token,
    refresh_token: store.login.refresh_token,
    selectedCustomer: store.login.selectedCustomer,
    usageFileLoading: store.customer.usageFileLoading
  });

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        fetchUsageFile
    }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(memo(UserUsage));
